/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower:wght@300;400;500&display=swap'); */

.as-page-container {
  background-image: url('/Users/macintoshhd/Documents/Open Woods/open-woods-app-new/src/images/Bankhead_National_Forest_Topo_Black_Tan.jpg');
  background-repeat: repeat;
  background-size: cover;
  background-position: flex-end;
  display: flex;
  flex-direction: row;
  height: 30vh;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 10vh; /* Adjust this if your navbar height changes */
  left: 0;
  right: 0;
  bottom: 0;
}

@media screen and (max-aspect-ratio: 2/3) {
  .as-page-container {
    background-size: auto 100%;
  }
}

.loading-dots {
  display: inline-block;
  font-size: 4rem;
  color: #cfcfc4fd;
}

.loading-dots span {
  animation: loadingDots 1.4s infinite both;
  margin: 0 2px;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingDots {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.custom-button {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
}

.upload-button {
  background-color: #cfcfc4fd !important;
  border-color: #cfcfc4fd !important;
  color: black !important;
}

.score-button {
  background-color: #fe7159ff !important;
  border-color: #fe7159ff !important;
  color: black !important; 
}
