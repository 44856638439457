@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower:wght@300;400;500&display=swap');

.home-container {
  background-image: url('/Users/macintoshhd/Documents/Open Woods/open-woods-app-new/src/images/Bankhead_National_Forest_Topo_tav_v6jpg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;

  /* border: 1px solid red; */
  height: 90vh;
  display: flex;
  width: 100vw;
}

.home-input-container {
  /* background-image: url('/Users/macintoshhd/Documents/Open Woods/open-woods-app-new/src/images/woods_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  /* border: 1px solid red; */
  height: 80%;
  display: flex;
  width: 100vw;
}

.output-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
  /* justify-content: end; */
  height: 100%;
  width: 100vw;
}

.output-box {
  /* position: absolute; */
  display: flex;
  width: 30%;
  flex-direction: row; /* Adjusted to column layout */
  justify-content: center;
  align-items: center; /* Centered horizontally */
  font-size: 1.6em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  color: rgb(255, 255, 255);
}

.final_price {

  position: absolute;
  /* align-items: center; */
  /* justify-content: center; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  color: green; 
}

/* Additional style for the arrowhead image */
.arrowhead-img {
  height: 65px;
  width: 160px;
}


/* flex-direction: row; */
/* border-radius: 25px !important;
height: 60%;
margin: 0; */
/* padding-left: 3rem;
padding-right: 3rem; */
/* width: 30%; */
/* justify-content: space-between; */
/* align-items: center; */
/* border: 4px solid red; */
/* } */





.home-form-container {
  /* background-image: url('/Users/macintoshhd/Documents/Open Woods/open-woods-app-new/src/images/woods_background.jpg');
  background-repeat: no-repeat;
  background-size: cover; */
  /* background-position: center; */
  align-items: center;
  justify-content: top;
  display: flex;
  /* padding-left: 6rem;
  padding-right: 6rem; */
  /* padding-top: 3rem; */
  padding-bottom: 0;
  /* border: 1px solid green; */
  height: 80vh;
  width: 100%;
  flex-direction: column;
}

.home-form {
  display: flex;
  background-color: rgba(197, 227, 218, 0) !important; 
  flex-direction: column;
  justify-content: center;
  padding-top: 2.5em;
  padding-bottom: 1.5em;
  border-radius: 25px !important;
  height: 60%;
  width: 40%;
  /* border: 3px solid rgb(152, 151, 151); */
  border-radius: 5px;
  flex-direction: column;
}

.form-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0); /* Adjust the alpha value as needed */
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  /* padding-bottom: 1.7rem; */
  margin-top: 1rem;
  font-weight: 600; /* bold font weight */
  letter-spacing: .09em ; /* Adjust the value as needed */
  font-size: 2.7em;
  color: rgb(54, 54, 54);
    line-height: 1; /* reduced line height */
    font-family: 'Open Sans' !important;    
  }


  .activity_icons{
    color: rgb(54, 54, 54,100);
    display: flex;

    justify-content: center;
    align-items: center;
    padding: 1em;
width: 10%!important;
/* height: 30%!important; */
  }

  .form-subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 230%;
    background-color: rgba(120, 120, 120, 0); /* Adjust the alpha value as needed */
    margin-top: 1rem;
    font-weight: 650; /* bold font weight */
    font-size: 1.3em;
    color: rgb(37, 37, 37); 

        letter-spacing: .1rem; /* increased letter spacing */
      line-height: 1; /* reduced line height */
      font-family: 'Indie Flower' !important;    
    }

  

.form-inputs {
  flex-direction: column;
  align-items: start; /* Change to left alignment */
  font-family: 'Open Sans' !important;   
 
  border-style: dashed;
  border-color: rgb(77, 77, 77, 70%);
  border-radius: 5%;
  border-width: .15cm;

  font-size: 140%; /* increased font size */
  color: rgb(54, 54, 54);

  font-weight: 700; /* bold font weight */
  /* padding-top: 10%;
  padding-top: 10%; */

padding-left: 3rem;
padding-right: 3rem;
/* padding-top: 2rem;   
padding-bottom: 4rem;    */
}

.form-buttons {
  padding: .7em;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600; /* Adjust this value as needed */
  justify-content: space-between;
  align-items: center;
  padding-left: 8rem;
  padding-right: 8rem;
}



.home-map-container {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: top;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 3rem;
  /* padding-bottom: 5rem; */
  height: 80vh;
  width: 50%;

  /* border: 4px solid green; */
}

.mapboxgl-canvas {
  display: flex !important;
  position: relative !important;
  background-color: white !important;
  flex-direction: column !important;
  left: 0 !important;
  top: 0 !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 25px !important;
  height: 100% !important;
  width: 100% !important;
}


.map {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85%;
  width: 85%;
  background-color: white;
  border-radius: 25px !important;
  border: 3px red;
  /* border-radius: 5px; */
}








  input {
  height: 1rem;
  margin-bottom: 1rem;
}

.reset-button {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600; /* Adjust this value as needed */
}



.submit-button {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600; /* Adjust this value as needed */
  display: flex;
  color: white;
  font-family: 'Open Sans', sans-serif;

  background-color:  #5cc87dff;
border-color: #5cc87dff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
/* padding2rem  ;    */
}

.submit-button .submit-toggle:focus .submit-button:hover {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500; /* Adjust this value as needed */
  background-color: #a0ab8aff;
  box-shadow: none;
  font-family: 'Open Sans', sans-serif;

  border-color:  #a0ab8aff; /* Set the custom outline color */
}


.total-price-input {
  width: 15%;
}



.home-input-labels {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: start;
}

.select {
    float: left;
    position: left;
    width: 30%;
    float: left;
    margin-right: 10px;
    margin-bottom: 12px;
    margin-top: 12px;
    margin-left: 10px;
    padding: 10;
}

.final-price {
  position: absolute;
  bottom: calc(1in + 30px);
  left: 96px;
  right: calc(100% - 330px);
  font-size: 4em;
  font-weight: 600; /* bold font weight */
  letter-spacing: 1px; /* increased letter spacing */
  line-height: 1; /* reduced line height */
  font-family: 'Lato', sans-serif; /* modern sans-serif font.........Helvetica Neue */
}


/* Activity and State */
.dropdown-button {
  color:#fe7159ff;
}

.dropdown-menu.show {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}

/* Activity and State */
.dropdown-button .dropdown-toggle {
  background-color: #fe7159ff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600; /* Adjust this value as needed */

  height: 40px;
  outline: none;
  box-shadow: none;
  border-radius: 0.5rem; /* Add rounded corners */
  border-color: #fe7159ff; /* Set the custom outline color */
}
/* Activity and State */
.dropdown-button .dropdown-toggle:focus {
  outline: none;
  box-shadow: none;
    border-color: #fe7159ff; /* Set the custom outline color */
}

/* Activity and State */
.dropdown-button .dropdown-toggle:active {
  background-color: #af4f3dff;
  box-shadow: none;
  border-color:  #af4f3dff;/* Set the custom outline color */
  max-height: 2000px;
  overflow-y: scroll;
}
/* Activity and State */
.selected-item {
  background-color: #778184ff !important;
  color: white;
}
/* Activity and State */
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item:focus {
  background-color: #778184ff;
  color: white;
}

/* Activity and State */
  .form-group label {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 24px;
    text-align: left;
    justify-content: flex-start;
    padding-left: 0; /* Remove the padding on the left */
    margin-right: 10px; /* Add margin to the right to create spacing between the label and container */
  }

/* Acreage and Duration */
.input-field {
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding: 8px;

  border: 1px solid gray;
  border-radius: 0 4px 4px 0;
  outline: none;
}