.App {
  text-align: center;
}

.navbar {
  background-color: #648275;
  height: 10vh;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
}

.navbar .container-fluid {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.logo {
  height: 8vh;
  max-height: 4rem;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  border-radius: 10px;  /* Add this line to create rounded corners */
}

.nav-link_style {
  padding: 0 1.875rem;
  padding-left: 1.875rem;
}

/* Add this new rule for the dropdown menu */
.navbar-nav .dropdown-menu {
  background-image: url('/Users/macintoshhd/Documents/Open Woods/open-woods-app-new/src/images/Bankhead_National_Forest_Topo_Black_Tan.jpg');
  background-size: cover;
  background-position: center;
  width: auto;
  min-width: 10rem;
  max-width: 15rem;
  border: 2px solid rgba(78, 100, 89, 0.7); /* Add orange border */
  border-radius: 10px; /* Add rounded corners */
  overflow: hidden; /* Ensure content doesn't overflow rounded corners */
}

.navbar-nav .dropdown-menu::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/Users/macintoshhd/Documents/Open Woods/open-woods-app-new/src/images/Bankhead_National_Forest_Topo_Off_White.png');

  /* background-color: rgba(100, 130, 117, 0.9); Semi-transparent green overlay */
  z-index: -1;
}

.navbar-nav .dropdown-menu .dropdown-item {
  color: #fff;
  position: relative;
  z-index: 1;
  background-image: url('/Users/macintoshhd/Documents/Open Woods/open-woods-app-new/src/images/Bankhead_National_Forest_Topo_Off_White.png');

}

.navbar-nav .dropdown-menu .dropdown-item:hover,
.navbar-nav .dropdown-menu .dropdown-item:focus {
  background-color: rgba(78, 100, 89, 0.7); /* Semi-transparent darker green */
}

@media (max-width: 768px) {
  .logo-container {
    margin-left: 1%;
  }

  .logo {
    height: 8vh;
    max-height: 5rem;
    border-radius: 10px;  /* Ensure this is also applied in mobile view */
  }

  .navbar {
    padding: 0 0.5rem;
  }

  .navbar-toggler {
    margin-right: 3%;
  }

  /* Add this rule to ensure the dropdown background is green on mobile */
  .navbar-collapse {
    background-color: #cfcfc4fd;
    background-image: none;
    width: 60%;
    max-width: 100%;
    position: absolute; /* Change from relative to absolute */
    top: 100%; /* Position it just below the navbar */
    right: 0; /* Align to the right */
    border-radius: 10px;
    border: 2px solid rgba(78, 100, 89, 0.7);
    overflow: hidden;
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.2); /* Optional: add shadow for depth */
  }

  .navbar {
    position: relative; /* Ensure the navbar is a positioning context for absolute elements */
  }

  .navbar-nav .nav-link {
    position: relative;
    z-index: 1;
    border-radius: 10px;  /* Add this line to create rounded corners */
    text-align: left; /* Align text to the left */
    padding-left: 2.5rem; /* Add some left padding */
    display: flex;
    align-items: center;
  }

  .navbar-nav .nav-link::before {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 0.5rem;
    position: absolute;
    left: 0.5rem;
  }

  /* Add specific icons for each menu item */
  .navbar-nav .nav-link[href="/"]::before {
    background-image: url('/Users/macintoshhd/Documents/Open Woods/open-woods-app-new/src/images/antler_orange.png');
  }

  .navbar-nav .nav-link[href="/about"]::before {
    background-image: url('/Users/macintoshhd/Documents/Open Woods/open-woods-app-new/src/images/about_icon_orange.png');
  }

  .navbar-nav .nav-link[href="/contact"]::before {
    background-image: url('/Users/macintoshhd/Documents/Open Woods/open-woods-app-new/src/images/walky_talky_orange.png');
  }

  /* Add this new rule to ensure all text in the dropdown is left-aligned */
  .navbar-nav {
    text-align: left;
  }

  /* Optionally, if you want to add some space between menu items */
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
  }
}
