/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower:wght@300;400;500&display=swap'); */

.contact-page-container {
  background-image: url('/Users/macintoshhd/Documents/Open Woods/open-woods-app-new/src/images/Bankhead_National_Forest_Topo_Black_Tan.jpg');
  background-repeat: repeat;
  background-size: cover;
  background-position: flex-end;
  display: flex;
  flex-direction: row;
  height: 30vh;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 10vh; /* Adjust this if your navbar height changes */
  left: 0;
  right: 0;
  bottom: 0;
}

@media screen and (max-aspect-ratio: 2/3) {
  .about-page-container {
    background-size: auto 100%;
   
  }
}

@media (max-width: 768px) {
  .contact-page-container {
    padding: 0.5rem !important;
    
  }

  .responsive-content {

    max-width: 100% !important;
    padding: 0.5rem !important;
  }

  .contact-form label {
    font-size: 0.9rem !important;
    
    margin-bottom: 0.1rem !important;
    color: #cfcfc4fd !important; /* Ensure label color is visible */
  }

  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    font-size: 0.9rem !important;
    padding: 0.4rem !important;
    margin-bottom: 0.3rem !important;
    background-color: #cfcfc4fd !important; /* Lighter background */
    color: #000000 !important; /* Ensure text is black for visibility */
    border: 1px solid #a0a096 !important; /* Add a border for definition */
    width: 100% !important; /* Ensure full width */
    box-sizing: border-box !important; /* Include padding and border in width */
  }

  .contact-form textarea {
    rows: 4;
  }

  .contact-form .row {
    display: flex;
    flex-direction: row;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    margin-bottom: 0.3rem !important;
  }

  .contact-form .col-md-6 {
    width: 50%;
    padding: 0 0.25rem;
  }

  .contact-form .mb-1,
  .contact-form .mb-4,
  .contact-form .mb-3 {
    margin-bottom: 0.3rem !important;
  }

  /* Ensure submit button text is visible */
  .contact-form button[type="submit"] {
    background-color: #fe7159ff !important;
    color: #ffffff !important;
    border: none !important;
  }
}

